import "expose-loader?Tether!tether";
import "bootstrap/dist/js/bootstrap.min";
import "flexibility";
import "bootstrap-touchspin";
import "jquery-touchswipe";
import "./selectors";

import "./responsive";
import "./checkout";
import "./customer";
import "./listing";
import "./product";
import "./cart";

import prestashop from "prestashop";
import EventEmitter from "events";
import DropDown from "./components/drop-down";
import Form from "./components/form";
import ProductMinitature from "./components/product-miniature";
import ProductSelect from "./components/product-select";
import TopMenu from "./components/top-menu";

import "./lib/bootstrap-filestyle.min";
import "./lib/jquery.scrollbox.min";

import "./components/block-cart";
import $ from "jquery";
/* eslint-enable */

import "./components/time-line";
import "./components/time-line/responsive";

import "slick-carousel";

// "inherit" EventEmitter
// eslint-disable-next-line
for (const i in EventEmitter.prototype) {
  prestashop[i] = EventEmitter.prototype[i];
}

$(document).ready(() => {
  const dropDownEl = $(".js-dropdown");
  const form = new Form();
  const topMenuEl = $('.js-top-menu ul[data-depth="0"]');
  const dropDown = new DropDown(dropDownEl);
  const topMenu = new TopMenu(topMenuEl);
  const productMinitature = new ProductMinitature();
  const productSelect = new ProductSelect();
  dropDown.init();
  form.init();
  topMenu.init();
  productMinitature.init();
  productSelect.init();

  $('.carousel[data-touch="true"]').swipe({
    swipe(event, direction) {
      if (direction === "left") {
        $(this).carousel("next");
      }
      if (direction === "right") {
        $(this).carousel("prev");
      }
    },
    allowPageScroll: "vertical",
  });

  $(".products-tabs-container .tabs-header button").click(function () {
    $(".tabs-header button").removeClass("active");
    $(this).addClass("active");
    const tab = $(this).data("tab");

    $(".products-tabs .products-tab").removeClass("active");
    $('.products-tabs .products-tab[data-tab="' + tab + '"]').addClass(
      "active"
    );
  });

  $(".news-tabs-container .tabs-header button").click(function () {
    $(".tabs-header button").removeClass("active");
    $(this).addClass("active");
    const tab = $(this).data("tab");

    $(".news-tabs .news-tab").removeClass("active");
    $('.news-tabs .news-tab[data-tab="' + tab + '"]').addClass("active");

    localStorage.setItem("newsActiveTab", tab);

  });

  let newsActiveTab = localStorage.getItem("newsActiveTab");
  if(newsActiveTab > 0)
   $('.tabs-header button[data-tab="' + newsActiveTab + '"]').click()


  $(".comments-carousel").slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: '<div class="slider-arrow prev"></div>',
    nextArrow: '<div class="slider-arrow next"></div>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
  });

  $("#carousel-linked-product > .products").slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: '<div class="slider-arrow prev"></div>',
    nextArrow: '<div class="slider-arrow next"></div>',
    centerMode: false,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
    ],
  });

  $("#carousel-accessories > .products").slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: '<div class="slider-arrow prev"></div>',
    nextArrow: '<div class="slider-arrow next"></div>',
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
    ],
  });

  $("#carousel-works > .products").slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: '<div class="slider-arrow prev"></div>',
    nextArrow: '<div class="slider-arrow next"></div>',
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
    ],
  });

  $("#carousel-discography > .products").slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: '<div class="slider-arrow prev"></div>',
    nextArrow: '<div class="slider-arrow next"></div>',
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
    ],
  });

  $("#supplier-read-more").on("click", function () {
    const $showButton = $("#supplier-read-more .show");
    const $hideButton = $("#supplier-read-more .hide");

    const $fullText = $('#full-text');
    const $truncatedText = $('#truncated-text');

    if ($showButton.is(':visible')) {
      $truncatedText.slideUp();
      $fullText.slideDown();

      $showButton.slideUp();
      $hideButton.slideDown();
    } else {
      $truncatedText.slideDown();
      $fullText.slideUp();

      $showButton.slideDown();
      $hideButton.slideUp();
    }
  });


  //customer form
  $('#field-pro-no').prop('checked', true);

  $('.custom-radio input[name="pro"]').on('change',function(){

    if($('#field-pro-no').prop('checked')){
      $("#instruments_list").hide();
    }else{
      $("#instruments_list").show();
    }
    
  });

  $('.show-password').on('click', function() {
    var passwordType = $(this).prev().attr('type');
    if (passwordType == 'text') {
      $(this).prev().attr('type', 'password');
      $(this).html('remove_red_eye');
    } else {
      $(this).prev().attr('type', 'text');
      $(this).html('visibility_off');
    }
  });
  $(document).ready(function() {
    // Add event listener to the modal
    $('#product-modal').on('click', function(event) {
      // Check if the clicked element is outside the modal content
      if ($(event.target).closest('#unzoom').length === 0) {
        // Close the modal
        //console.log('test');
        //$('#product-modal').modal('hide');
      }
    });
  });



});

